import React from "react"
import NotFoundPage from "../components/404Page/404Page"
import Seo from "../components/SEO/Seo"

const seoContent = {
  title: "Últimas noticias de Argentina y el mundo - LA NACION",
  description:
    "Todas las noticias de Argentina y el mundo: últimas noticias en actualidad, deportes, coronavirus, economía, política, y tecnología. Mantenete informado sobre las novedades de Argentina en LA NACION.",
  image:
    "https://res.cloudinary.com/anterux/image/upload/f_auto/v1626304075/diario-digital-clone/placeholderLN.jpg",
  url: ``,
  article: false,
};

const Page = () => {
  return (
    <>
      <Seo content={seoContent} />
      <NotFoundPage />
    </>
  )
}

export default Page
